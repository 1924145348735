import React from "react";
import {Link, useI18next} from "gatsby-plugin-react-i18next";

const ArchiveList = ({elements}) => {
    const {language} = useI18next()
    return (
        <ul>
            {elements.map(e => (
                <li key={e.title}>
                    <Link to={e.slug} language={language}>{e.title}</Link>
                    <a href={e.ctfTimeHref} target="_blank" rel="noreferrer noopener">&nbsp; (CTFtime)</a>
                </li>
            ))}
        </ul>
    )
}

export default ArchiveList
