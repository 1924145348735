import React from "react";
import {graphql} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import ArchiveList from "../components/archive/archiveList";
import Seo from "../components/seo/seo";

const Archive = ({data, location}) => {
    const {t, language} = useI18next()
    const ctfs = data.allCtfJson.nodes
    return (
        <>
            <Seo title={t('archive_title')} lang={language} description={t("archive_text")}
                 pathname={location.pathname}/>
            <section>
                <div className="grid-x">
                    <div className="large-offset-1 large-10 cell cell-text">
                        <h1>{t("archive_header")}</h1>
                        {t("archive_text")}
                        <hr/>
                        <ArchiveList elements={ctfs}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Archive

export const query = graphql`
    query($language: String!){
        allCtfJson(sort: {fields: [startDate],order: [ASC]},filter: {current: {eq: false}}){
            nodes{
                title
                slug
                ctfTimeHref
            }
        }
        locales: allLocale(filter: {language: {eq: $language}}){
            edges{
                node{
                    ns
                    data
                    language
                }
            }
        }
    }
`
